import type { OperationVariables } from '@apollo/client';
import { ItemType } from '../../../types/enums';
import mergeMessageVariantProps from '../../messages/MessageView/MessageViewDefaultProps';
import mergeNodeVariantProps from '../../nodes/NodeView/NodeViewDefaultProps';
import mergeUserVariantProps from '../../users/UserView/UserViewDefaultProps';
import type { ItemViewTypeAndProps, ItemViewVariant } from '../../entities/types';
import mergeMessageSearchVariantProps from '../../messages/MessageSearchView/MessageSearchViewDefaultProps';
import mergeNodeSearchVariantProps from '../../nodes/NodeSearchView/NodeSearchViewDefaultProps';
import mergeUserSearchVariantProps from '../../users/UserSearchView/UserSearchViewDefaultProps';
import mergeTagVariantProps from '../../tags/TagView/TagViewDefaultProps';
import mergeIdeaStatusVariantProps from '../../ideas/IdeaStatusView/IdeaStatusViewDefaultProps';
import mergeGuideViewVariantProps from '../../guides/GuideView/GuideViewDefaultProps';

const typeToMergeFunctionMap: Record<
  ItemType,
  (
    variant: ItemViewTypeAndProps<ItemType, ItemViewVariant<ItemType>>,
    props: Record<string, unknown>
  ) => Record<string, unknown>
> = {
  [ItemType.ATTACHMENT]: null,
  [ItemType.MESSAGE]: mergeMessageVariantProps,
  [ItemType.MESSAGE_SEARCH]: mergeMessageSearchVariantProps,
  [ItemType.MODERATED_MESSAGE]: null,
  [ItemType.NODE]: mergeNodeVariantProps,
  [ItemType.NOTIFICATION]: null,
  [ItemType.PRIVATE_CONVERSATION]: null,
  [ItemType.TAG]: mergeTagVariantProps,
  [ItemType.PRIVATE_NOTE]: null,
  [ItemType.SUBSCRIPTION]: null,
  [ItemType.USER]: mergeUserVariantProps,
  [ItemType.USER_INVITE]: null,
  [ItemType.ABUSE_REPORTED_MESSAGE]: null,
  [ItemType.ABUSE_REPORTED_PRIVATE_MESSAGE]: null,
  [ItemType.ABUSE_REPORTED_USER]: null,
  [ItemType.REJECTED_PRIVATE_MESSAGE]: null,
  [ItemType.NODE_SEARCH]: mergeNodeSearchVariantProps,
  [ItemType.USER_SEARCH]: mergeUserSearchVariantProps,
  [ItemType.BAN_RULE]: null,
  [ItemType.FILTER_EVENTS]: null,
  [ItemType.BADGE]: null,
  [ItemType.BADGE_SET]: null,
  [ItemType.IDEA_STATUS]: mergeIdeaStatusVariantProps,
  [ItemType.GUIDE]: mergeGuideViewVariantProps,
  [ItemType.ARCHIVED_MESSAGE]: null
};

/**
 * Merges default props for the variant with any props specified ON the variant, and with any additional props passed in.
 * @param type The entity type
 * @param variantAndProps The type variant and props
 * @param additionalProps Any additional props.
 */
function mergeProps<Type extends ItemType, PropsType extends OperationVariables>(
  type: Type,
  variantAndProps: ItemViewTypeAndProps<Type, ItemViewVariant<Type>>,
  additionalProps?: PropsType
): PropsType {
  return (
    (typeToMergeFunctionMap[type]?.(variantAndProps, additionalProps) as PropsType) ??
    additionalProps
  );
}

export default mergeProps;
